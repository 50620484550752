import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Cindy Beals" subtitle="Director of Learning" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQb/xAAYAQACAwAAAAAAAAAAAAAAAAACAwABBP/aAAwDAQACEAMQAAABjPp9AU5o0Yt+tLzO1YfLH//EABwQAAICAgMAAAAAAAAAAAAAAAEDAAIEERITIv/aAAgBAQABBQIDZti2rSLOrsOlTGT2FnhPERDgosbZhSgMp//EABkRAQACAwAAAAAAAAAAAAAAAAEAAhAREv/aAAgBAwEBPwGgJgdE5Z//xAAZEQEAAgMAAAAAAAAAAAAAAAABAAIQESH/2gAIAQIBAT8Buo4ezc//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhAhASMZH/2gAIAQEABj8CgnSY3Wr6RlGnTcl+HJ5M/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBEP/aAAgBAQABPyFUHZePgD2AwZZgs8A0XtEMC0nCyNcTAcYDrnEQAvAJ/9oADAMBAAIAAwAAABDnBr7/xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxBQtsOZ7Ddv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBASIU1Zen/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFxUZGx/9oACAEBAAE/EF2hVL9B2/YlNM0im+oclrQVUjuWmZA4XglAdKkecTdHqFuoilIu4kScBwlrKoouCf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg",
              "srcSet": ["/static/d96684a13003e39bf26e4119d235d896/f93b5/01.jpg 300w", "/static/d96684a13003e39bf26e4119d235d896/b4294/01.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`Curriculum Updates: Blended / Remote Learning`}</p>
    </blockquote>
    <p>{`Every year, the International Baccalaureate (IB) makes adjustments to aspects of the curriculum, based on a seven year curriculum review cycle.  This is done to ensure the learning represents educational research and remains appropriately meaningful in a changing world.`}</p>
    <p>{`In this unprecedented year of a pandemic, they included many more changes than are typically involved, and planned adjustments to the Theatre curriculum were postponed. Here we outline the significant changes to PSI’s curriculum that we’ve undergone as a result. Students experienced a condensed curriculum in both the 2019 – 2020 and 2020 – 2021 school years. This curriculum focused on the content, concepts and skills that will be most important to their success in the future.`}</p>
    <p>{`This year, in the Diploma Programme (DP), the IB recommended, “ensuring more manageable, practical, achievable teaching and learning experiences.”  Below is a list of modifications to the DP Programme by subject:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Language A: language and literature: Removal of Paper 2`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Language ab initio:  Section 1 only one question, Section 2 removal of listening comprehension, and in the Internal Assessments (IA) students get a choice from all five themes so they have more choice in what is familiar and comfortable for them`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Language B: Listening comprehension has been removed, in Standard Level (SL) students get a  choice from all five themes so they have more choice in what is familiar and comfortable for them, and in Higher level (HL) students may respond to stimuli from one text that they studied in depth with another text more briefly studied.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Business Management: students respond to one question in paper 1 and one section of the exam is removed and additionally in HL, more secondary data in the internal assessment.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Economics: students only have to answer one question, not two in Paper 2.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Geography: on Paper 1, students answer in depth for one option and one section has been removed from Paper 2.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Global Politics: removal of one paper and for higher level the removal of one presentation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`History: removal of one essay from Paper 2 and in higher level , removal of one essay from Paper 3.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Biology: Allowing simulations instead of physical labs as needed, removal of the requirement for group work and paper 3.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Chemistry: Allowing simulations instead of physical labs as needed, removal of the requirement for group work and paper 3.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Design Technology: removal of the requirement for group work and paper 3, and allowing for independent work to replace teacher directed instruction.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Environmental Systems and Societies (ESS): replacement of teacher directed instruction with virtual tasks or simulations.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Physics: Allowing simulations instead of physical labs as needed, removal of the requirement for group work and paper 3.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mathematics: analysis and approaches: no changes to curriculum only exam mitigations to offset learning disruptions`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mathematics: applications and interpretation: no changes to curriculum only exam mitigations to offset learning disruptions`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Music:`}<br parentName="p"></br>{`
`}{`• 2021 graduates: Listening paper removed, a shorter performance and fewer pieces needed to be created.`}<br parentName="p"></br>{`
`}{`• 2022 graduates: New curriculum with removal of the Standard Level presentation requirement and the Higher Level has removed the contemporary music maker presentation requirements.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Theatre: collaborative project removed`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Visual Arts: portfolio only required one format and the number of pieces required in the portfolio is reduced to 4 – 6 in standard courses and 7 – 10 in higher level courses.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Theory of Knowledge: no adjustments`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Creativity, Activity, Service (CAS): flexibility in the completion of the requirement.`}</p>
      </li>
    </ul>
    <p>{`This year the IB assured PYP and MYP schools in the reassurance that “we understand that learning and teaching may look and feel very different now.”  As such, flexibility with units was afforded so that schools might respond to “context and circumstances, to optimize well-being, continuity of learning and to drive learning forward.”  As such temporary adjustments were made in the 2020 – 2021 school year to PYP and MYP units to accommodate instruction through  a combination of blended and remote learning.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      